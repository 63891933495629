import * as React from 'react';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import HomeContainer from 'containers/Home';
import { GetServerSidePropsContext } from 'next';
import { CONTENTFUL_ASSET, getAsssetByTitle } from 'utils/contentful/asset';
import { DEFAULT_LANG } from 'utils/lang';
import { ContentfulAsset } from 'model/contentful/asset';
import { DehydratedState, QueryClient, dehydrate } from '@tanstack/react-query';
import { FEATURED_SECTION_LIST } from 'utils/contentful/featuredList';
import { getFeaturedListKeyByType } from 'api/reactQuery/featuredItems';
import getFeaturedListItems from 'api/getFeaturedListItems';
import { defaultGetStaticProps } from 'utils/staticPropsHelper';
import { BLOG_LIST_TYPE, BlogItemBase, getBlogListByType } from 'utils/contentful/blog';

interface Props {
  howItWorksImage: ContentfulAsset;
  featuredBlogItems: Array<BlogItemBase> | null;
  heroSliderImages: Record<string, ContentfulAsset | null>;
}

const Home: React.FC<Props> = ({ howItWorksImage, featuredBlogItems, heroSliderImages }) => {
  const { t } = useTranslation();

  return (
    <div className="Home w-full">
      <Head>
        <title>{t('home.theBest')}</title>
      </Head>
      <HomeContainer
        howItWorksImage={howItWorksImage}
        featuredBlogItems={featuredBlogItems}
        heroSliderImages={heroSliderImages}
      />
    </div>
  );
};

export const getStaticProps = async (context: GetServerSidePropsContext) => {
  const { preview } = context;
  const { props } = await defaultGetStaticProps(context);
  const queryClient = new QueryClient();
  const heroSliderImages: Record<string, ContentfulAsset | null> = {
    [CONTENTFUL_ASSET.HOME_ROW1]: null,
    [CONTENTFUL_ASSET.HOME_ROW2]: null,
    [CONTENTFUL_ASSET.HOME_ROW3]: null
  };
  let howItWorksImage;
  let featuredBlogItems;

  try {
    await queryClient.fetchQuery(
      getFeaturedListKeyByType(FEATURED_SECTION_LIST.HOME_INLINE),
      async () => {
        return await getFeaturedListItems(FEATURED_SECTION_LIST.HOME_INLINE, props.lang);
      }
    );
    featuredBlogItems = await getBlogListByType({
      type: BLOG_LIST_TYPE.HOME,
      preview,
      locale: props.lang,
      blogItemLimit: 3
    });
    howItWorksImage = await getAsssetByTitle(
      CONTENTFUL_ASSET.HOME_HOW_IT_WORKS,
      preview,
      DEFAULT_LANG
    );
    heroSliderImages[CONTENTFUL_ASSET.HOME_ROW1] =
      (await getAsssetByTitle(CONTENTFUL_ASSET.HOME_ROW1, preview, DEFAULT_LANG)) || null;
    heroSliderImages[CONTENTFUL_ASSET.HOME_ROW2] =
      (await getAsssetByTitle(CONTENTFUL_ASSET.HOME_ROW2, preview, DEFAULT_LANG)) || null;
    heroSliderImages[CONTENTFUL_ASSET.HOME_ROW3] =
      (await getAsssetByTitle(CONTENTFUL_ASSET.HOME_ROW3, preview, DEFAULT_LANG)) || null;
  } catch (e: any) {
    console.error('Error fetching contentful data: ', e);
  }

  return {
    props: {
      ...props,
      howItWorksImage: howItWorksImage || null,
      featuredBlogItems: featuredBlogItems || null,
      heroSliderImages: heroSliderImages,
      dehydratedState: {
        queries: [...props.dehydratedState.queries, ...dehydrate(queryClient).queries]
      } as DehydratedState
    },
    revalidate: 60
  };
};

export default Home;
