import { IProductTypes } from '@poinz/api';
import { FETCH_KEYS } from './';

interface IGetSearchFetchKeyArgs {
  category?: string;
  page: number;
  query?: string;
  businessProfileIds?: string[];
  searchableTypes?: IProductTypes[];
  lastChance?: boolean;
  labels?: string[];
  cashbackFrom?: string[];
  cashbackTo?: string[];
  priceFrom?: string;
  priceTo?: string;
  sort?: string;
}

export const getSearchFetchKey = ({
  category,
  page,
  query,
  businessProfileIds,
  searchableTypes,
  lastChance,
  labels,
  cashbackFrom,
  cashbackTo,
  priceFrom,
  priceTo,
  sort
}: IGetSearchFetchKeyArgs) => {
  return [
    FETCH_KEYS.SEARCH,
    category,
    page,
    query,
    businessProfileIds,
    searchableTypes,
    lastChance,
    labels,
    cashbackFrom,
    cashbackTo,
    priceFrom,
    priceTo,
    sort
  ];
};
