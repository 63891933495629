import * as React from 'react';
import Image from 'next/image';
import { useInView } from 'react-intersection-observer';
import { useAppContext } from 'contexts/app';
import clsx from 'clsx';

interface Props {
  children: React.ReactNode;
  src: string;
  overlayClass?: string;
  className?: string;
}

const HeroImage: React.FC<Props> = ({ children, src, overlayClass, className }) => {
  return (
    <div className={clsx('HeroImage relative w-screen h-screen -mt-[84px]', className)}>
      <Image loading="lazy" alt="hero" src={src} fill className="object-cover absolute z-0" />
      <div className={clsx('relative w-full h-full bg-[#171717]/[0.6] z-100', overlayClass)}>
        {children}
      </div>
    </div>
  );
};

export default HeroImage;
