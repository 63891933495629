import * as React from 'react';
import { getItemLink } from 'utils/featuredLIst';
import Link from 'components/Link';
import Paragraph from 'components/text/Paragraph';
import { useTranslation } from 'next-i18next';
import { GlobalSearchItem } from 'components/GlobalSearch/GlobalSearch.utils';
import { Search } from 'lucide-react';
import Heading from 'components/text/Heading';
import clsx from 'clsx';

interface Props {
  items: Array<GlobalSearchItem>;
  isOpen: boolean;
  value: string;
}

const GlobalSearchItemsDesktop: React.FC<Props> = ({ items, isOpen = false, value }) => {
  const { t } = useTranslation();

  if (!items?.length || !isOpen) return null;

  return (
    <div
      className={clsx(
        'GlobalSearchItemsDesktop absolute bg-white border border-neutral-900 w-full top-14 md:top-[68px] rounded-2xl md:rounded-3xl p-6 z-30 opacity-0 transition-all shadow-globalSearchContextMenu',
        {
          ['!opacity-100']: isOpen
        }
      )}
    >
      {!value && (
        <Heading variant="h4" className="mb-6 md:mb-4">
          {t('home.search.brandHighlights')}
        </Heading>
      )}
      <div className="flex flex-col gap-6 md:gap-3 max-h-[200px] overflow-auto">
        {items.map((item, index) => (
          <Link
            key={`search-suggestion-${item.slug}-${index}`}
            href={getItemLink(item?.entityType, item?.slug) || ''}
            className="flex gap-3 md:gap-6 items-center hover:!text-neutral-500"
          >
            <Search strokeWidth={1.5} size={16} className="min-w-4" />
            <Paragraph
              className="hover:!text-inherit text-ellipsis overflow-hidden whitespace-nowrap text-sm md:text-base"
              variant="body1"
            >
              <span className="font-semibold">{item.businessName}</span> {item.label}
            </Paragraph>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default GlobalSearchItemsDesktop;
