import * as React from 'react';
import clsx from 'clsx';
import { ContentfulAsset } from 'model/contentful/asset';
import HomeHero from './HomeHero';
import PromoStrip from 'components/PromoStrip';
import { useGetHomePromos } from './Home.utils';
import dynamic from 'next/dynamic';
import HomeHowItWorks from './HomeHowItWorks';
import { BlogItemBase } from 'utils/contentful/blog';
import BlogFeaturedSection from './BlogFeaturedSection';
import TrustpilotHome from './TrustpilotHome';

const HomeColorShiftStaticSections = dynamic(() => import('./HomeColorShiftStaticSections'));
const HomeInlineFeaturedSection = dynamic(() => import('./HomeInlineFeaturedSection'));
const HomeSignUp = dynamic(() => import('./HomeSignUp'));

interface Props {
  howItWorksImage: ContentfulAsset;
  featuredBlogItems: Array<BlogItemBase> | null;
  heroSliderImages: Record<string, ContentfulAsset | null>;
}

const Home: React.FC<Props> = ({ howItWorksImage, featuredBlogItems, heroSliderImages }) => {
  const promoLabels = useGetHomePromos();
  return (
    <div className={clsx('Home -mb-[95px] md:-mb-[127px]')}>
      <HomeHero images={heroSliderImages} />
      <HomeColorShiftStaticSections />
      <HomeHowItWorks image={howItWorksImage} />
      <PromoStrip labels={promoLabels} />
      <HomeInlineFeaturedSection />
      <BlogFeaturedSection items={featuredBlogItems} />
      <TrustpilotHome />
      <HomeSignUp />
    </div>
  );
};

export default Home;
