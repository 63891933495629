import * as React from 'react';
import Heading from 'components/text/Heading';
import { useTranslation } from 'next-i18next';
import Paragraph from 'components/text/Paragraph';
import View from 'components/View';
import { GlobalSearchLarge } from 'components/GlobalSearch';
import { ContentfulAsset } from 'model/contentful/asset';
import { CONTENTFUL_ASSET } from 'utils/contentful/asset';

interface Props {
  images: Record<string, ContentfulAsset | null>;
}

const HomeHero: React.FC<Props> = ({ images }) => {
  const { t } = useTranslation();

  if (!images) return null;

  return (
    <div className="relative min-h-screen h-full min-w-screen flex flex-col gap-8">
      <View className="flex flex-col gap-12 justify-center items-center mt-16 md:mt-24">
        <div className="w-full md:max-w-[600px] text-center">
          <Heading
            variant="h1"
            className="!leading-[1.125] !text-[2.25rem] md:!text-[3rem] whitespace-pre-line"
          >
            {t('home.effortlessSaving')}
          </Heading>
          <Paragraph
            variant="body2"
            className="text-base md:text-xl mt-2 md:mt-4 md:whitespace-pre-line"
          >
            {t('home.neverShopWithoutCashback')}
          </Paragraph>
        </div>
      </View>
      <div className="relative grid grid-rows-3 gap-[1vh] h-full overflow-x-hidden grow">
        <GlobalSearchLarge />
        {images[CONTENTFUL_ASSET.HOME_ROW1] && (
          <div
            className="w-[400vw] bg-contain bg-repeat-x animate-slide-right"
            style={{
              backgroundImage: `url('${images[CONTENTFUL_ASSET.HOME_ROW1]?.url}')`
            }}
          />
        )}
        {images[CONTENTFUL_ASSET.HOME_ROW2] && (
          <div
            className="w-[400vw] bg-contain bg-repeat-x animate-slide-left"
            style={{
              backgroundImage: `url('${images[CONTENTFUL_ASSET.HOME_ROW2]?.url}')`
            }}
          />
        )}
        {images[CONTENTFUL_ASSET.HOME_ROW3] && (
          <div
            className="w-[400vw] bg-contain bg-repeat-x animate-slide-right"
            style={{
              backgroundImage: `url('${images[CONTENTFUL_ASSET.HOME_ROW3]?.url}')`
            }}
          />
        )}
      </div>
    </div>
  );
};

export default HomeHero;
