import * as React from 'react';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';

interface Props {
  labels: Array<string>;
}

const PromoStrip: React.FC<Props> = ({ labels }) => {
  return (
    <div className="PromoStrip flex justify-start overflow-hidden w-full whitespace-nowrap rtl-container p-6 bg-gray-100 gap-12 transition-all">
      {[...Array(10)].map((key, i) => (
        <Paragraph
          key={`strip-label-${key}-${i}`}
          className="flex gap-12 animate-[move-rtl_30s_linear_infinite] transition-all text-base"
          variant="subtitle"
        >
          {labels.map((label, j) => (
            <span className="text-gray-400" key={`strip-label-${key}-${i}-${label}-${j}`}>
              {label}
            </span>
          ))}
        </Paragraph>
      ))}
    </div>
  );
};

export default PromoStrip;
