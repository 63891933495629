import { ContentfulAsset } from 'model/contentful/asset';
import { fetchGraphQL } from './api';

export const CONTENTFUL_ASSET = Object.freeze({
  ABOUT_US_LARGE: 'about-us-large',
  ABOUT_US_VISION: 'about-us-vision',
  BECOME_PARTNER: 'become-partner',
  CONTACT_US_LARGE: 'contact-us-large',
  HOME_HERO: 'home_hero',
  HOME_HOW_IT_WORKS: 'home_how_it_works',
  HOME_ROW1: 'home-row1-bg',
  HOME_ROW2: 'home-row2-bg',
  HOME_ROW3: 'home-row3-bg',
  SL_CARDS: 'sl cards',
  MONEYLAND: 'moneyland',
  INSTAGRAM_1: 'instagram_1',
  INSTAGRAM_2: 'instagram_2',
  INSTAGRAM_3: 'instagram_3',
  INSTAGRAM_4: 'instagram_4',
  EXTENSION_BRANDS_MOBILE: 'extension-brands-mobile',
  EXTENSION_BRANDS_DESKTOP: 'extension-brands-desktop',
  EXTENSION_HERO: 'extension-hero'
});

export const getAsssetByTitle = async (
  title,
  preview = false,
  locale
): Promise<ContentfulAsset> => {
  const query = `query {
    assetCollection(where:{title:"${title}"}, locale: "${locale}") {
      items {
        title,
        url
      }
    }
  }`;

  const results = await fetchGraphQL(query, preview);
  return results?.data?.assetCollection?.items?.[0];
};
