import * as React from 'react';

interface Props {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
}
const ClickOutside: React.FC<Props> = ({ children, onClick, className }) => {
  const wrapperRef = React.useRef<any>();

  const handleClickListener = React.useCallback(
    event => {
      if (wrapperRef.current) {
        const clickedInside = wrapperRef && wrapperRef.current.contains(event.target);
        if (clickedInside) return;
        else onClick();
      }
    },
    [onClick]
  );

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickListener);

    return () => {
      document.removeEventListener('mousedown', handleClickListener);
    };
  }, [handleClickListener]);

  return (
    <div ref={wrapperRef} className={`${className || ''}`}>
      {children}
    </div>
  );
};

export default ClickOutside;
