import * as React from 'react';
import { useRouter } from 'next/router';

export enum TrustpilotWidgetType {
  LARGE = 'LARGE',
  SMALL = 'SMALL'
}

export enum TrustpilotWidgetTheme {
  LIGHT = 'light',
  DARK = 'dark'
}

interface Props {
  type?: TrustpilotWidgetType;
  theme?: TrustpilotWidgetTheme;
}

const config = {
  [TrustpilotWidgetType.LARGE]: {
    ['data-template-id']: '5419b6ffb0d04a076446a9af',
    ['data-businessunit-id']: '5e3bd8a22ae30b0001ccc45b',
    ['data-style-height']: '20px',
    ['data-style-width']: '100%'
  },
  [TrustpilotWidgetType.SMALL]: {
    ['data-template-id']: '56278e9abfbbba0bdcd568bc',
    ['data-businessunit-id']: '5e3bd8a22ae30b0001ccc45b',
    ['data-style-height']: '52px',
    ['data-style-width']: '100px'
  }
};

const TrustpilotWidget: React.FC<Props> = ({
  theme = TrustpilotWidgetTheme.DARK,
  type = TrustpilotWidgetType.LARGE
}) => {
  const { locale } = useRouter();
  const ref = React.useRef<HTMLDivElement | null>(null);

  // reinit trustpilot widget
  React.useEffect(() => {
    if ((window.Trustpilot as any) && ref) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [ref, locale]);

  return (
    <div
      className="trustpilot-widget relative"
      ref={ref}
      data-theme={theme}
      data-locale={locale}
      {...config[type]}
    >
      <a href="https://de.trustpilot.com/review/poinz.ch" target="_blank" rel="noreferrer">
        Trustpilot
      </a>
    </div>
  );
};

export default TrustpilotWidget;
