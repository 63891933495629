import clsx from 'clsx';
import { Search, X } from 'lucide-react';
import { useTranslation } from 'next-i18next';
import * as React from 'react';
import GlobalSearchItemsDesktop from './GlobalSearchItemsDesktop';
import { useGlobalSearch } from 'hooks/useGlobalSearch';
import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import { routes } from 'utils/routes';
import ClickOutside from 'components/ClickOutside';
import analytics, { ANALYTIC_EVENTS } from 'utils/firebase/analytics';

interface Props {
  className?: string;
}

const GlobalSearchLarge: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  const { data, getData } = useGlobalSearch();
  const router = useRouter();

  const getDataDebounced = debounce(async val => {
    await getData(val);
    analytics.logEvent(ANALYTIC_EVENTS.HOME_SEARCH, { search: val });
  }, 300);

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setValue(val);
    getDataDebounced(val);
  };

  const handleClearSearch = () => {
    setValue('');
    getDataDebounced('');
  };

  const handleKeyPress = React.useCallback(
    e => {
      if (e.keyCode == 13 && !!value) {
        router.push({ pathname: routes.shop.index(), query: { query: value } });
      }
    },
    [router, value]
  );

  return (
    <ClickOutside
      onClick={() => setIsOpen(false)}
      className="absolute top-6 w-full max-w-[90%] md:max-w-xl left-1/2 transform -translate-x-1/2 z-10"
    >
      <div className={clsx('GlobalSearchLarge relative w-full mx-auto', className)}>
        <input
          value={value}
          onChange={onChange}
          onFocus={() => setIsOpen(true)}
          onKeyDown={handleKeyPress}
          aria-label="shop search"
          className={clsx(
            'w-full bg-white pl-10 pr-4 sm:px-[60px] py-3 sm:py-4 rounded-[100px] border-[1.5px] border-neutral-900 leading-6 outline-none transition-all text-base text-ellipsis shadow-globalSearch',
            {
              ['placeholder:!text-neutral-500']: isOpen
            }
          )}
          placeholder={t('home.searchPlaceholder') as string}
        />
        <Search
          className={clsx(
            'absolute top-[17px] left-4 sm:top-[22px] sm:left-6 z-20 transition-all',
            {
              ['!text-neutral-900']: isOpen
            }
          )}
          strokeWidth={2}
          size={16}
        />
        {value && (
          <X
            className={clsx(
              'absolute top-[15px] right-4 sm:top-[19px] sm:right-6 z-20 cursor-pointer text-white',
              {
                ['!text-neutral-900']: isOpen
              }
            )}
            strokeWidth={2}
            size={20}
            onClick={handleClearSearch}
          />
        )}
        <GlobalSearchItemsDesktop items={data} isOpen={isOpen} value={value} />
      </div>
    </ClickOutside>
  );
};

export default GlobalSearchLarge;
