import * as React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Button from 'components/Button';
import clsx from 'clsx';

interface Props {
  carouselRef: {
    current: {
      slickPrev: () => void;
      slickNext: () => void;
      slickGoTo: (slide: number) => void;
    };
  };
  numberOfItems: number;
  nextSlideIndex: number;
  className: string;
  size: 'sm' | 'lg';
}

const HowItWorksCarouselArrows: React.FC<Props> = ({
  carouselRef,
  numberOfItems,
  nextSlideIndex,
  className,
  size
}) => {
  React.useEffect(() => {
    setTimeout(() => {
      if (carouselRef.current) {
        carouselRef.current.slickGoTo(0);
      }
    }, 100);
  }, [carouselRef]);

  const canGoNext = nextSlideIndex < numberOfItems - 1;
  const canGoBack = nextSlideIndex > 0;

  const handlePrev = () => {
    // if (carouselRef?.current && canGoBack) {
    carouselRef.current.slickPrev();
    // }
  };

  const handleNext = () => {
    // if (carouselRef?.current && canGoNext) {
    carouselRef.current.slickNext();
    // }
  };

  const iconSize = size === 'lg' ? 24 : 16;
  const buttonSizeClass = size === 'lg' ? 'w-12 h-12' : 'w-8 h-8';

  return (
    <div className={clsx('flex mt-6 sm:mt-8 gap-3 sm:gap-6', className)}>
      <Button
        onClick={handlePrev}
        className={clsx(
          'rounded-full bg-white flex items-center justify-center cursor-pointer !p-0',
          buttonSizeClass
        )}
        // disabled={!canGoBack}
      >
        <ChevronLeft strokeWidth={2} className="text-neutral-900" size={iconSize} />
      </Button>
      <Button
        onClick={handleNext}
        className={clsx(
          'rounded-full bg-white flex items-center justify-center cursor-pointer !p-0',
          buttonSizeClass
        )}
        // disabled={!canGoNext}
      >
        <ChevronRight strokeWidth={2} className="!text-neutral-900" size={iconSize} />
      </Button>
    </div>
  );
};

export default HowItWorksCarouselArrows;
