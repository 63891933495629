import { useRouter } from 'next/router';
import { Language } from 'types/lang';

const enPromos = [
  '★★★★★ 1’000+ reviews',
  '1.2 million app downloads',
  'CHF 1’000’000 cashback paid out',
  '1’500+ brands',
  'cashback market leader',
  '200’000+ active users',
  '400+ trusted cashback partners'
];
const dePromos = [
  '★★★★★ 1’000+ Bewertungen',
  '1.2 Millionen App-Downloads',
  'CHF 1’000’000+ Cashback ausbezahlt',
  '1’500+ Marken',
  'Cashback-Marktführer',
  '200’000+ aktive Benutzer',
  '400+ vertrauenswürdige Cashback-Partner'
];
const itPromos = [
  '★★★★★ Oltre 1.000 valutazioni',
  '1.2 milioni di download dell’app',
  '1’000’000 di CHF di cashback versati',
  'Oltre 1’500 marchi',
  'Leader del mercato del cashback',
  'Oltre 200’000 utenti attivi',
  'Oltre 400 partner di fiducia per il cashback'
];
const frPromos = [
  '★★★★★ 1’000+ évaluations',
  '1.2 million de téléchargements d’apps',
  'CHF 1’000’000+ de cashback versé',
  '1’500+ marques',
  'Leader du marché du cashback',
  '200’000+ utilisateurs actifs',
  '400+ partenaires Cashback de confiance'
];

export const useGetHomePromos = () => {
  const { locale } = useRouter();

  switch (locale) {
    case Language.DE:
      return dePromos;
    case Language.EN:
      return enPromos;
    case Language.IT:
      return itPromos;
    case Language.FR:
      return frPromos;
    default:
      return dePromos;
  }
};
