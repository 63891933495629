import * as React from 'react';
import clsx from 'clsx';
import { ContentfulAsset } from 'model/contentful/asset';
import HowItWorksCarousel, {
  HowItWorksCarouselItem,
  HowItWorksCarouselArrows
} from './HowItWorksCarousel';
import { useTranslation } from 'next-i18next';
import Heading from 'components/text/Heading';
import HeroImage from 'components/HeroImage';
import Paragraph from 'components/text/Paragraph';
import { useGetHowItWorksCarouselItems } from './HomeHowItWorks.utils';

interface Props {
  image: ContentfulAsset;
}

const HomeHowItWorks: React.FC<Props> = ({ image }) => {
  const { t } = useTranslation();
  const [nextSlideIndex, setNextSlideIndex] = React.useState(0);
  const carouselRef = React.useRef<any>(null);

  const handleOnSlide = next => {
    setNextSlideIndex(next);
  };

  const items = useGetHowItWorksCarouselItems();

  return (
    <div className={clsx('HomeHowItWorks relative w-screen h-full')}>
      <HeroImage className="mt-0 !h-full sm:!h-screen" src={image?.url}>
        <div className="flex flex-col sm:items-center sm:flex-row sm:h-full w-full py-24 sm:px-0 sm:py-0 gap-12 sm:gap-16 overflow-hidden">
          <div className="sm:flex-1 sm:max-w-[50%] px-6 sm:px-0">
            <div className="sm:mt-12 flex flex-col sm:max-w-[474px] sm:pl-6 ml-auto">
              <Heading className="text-white" variant="h2">
                {t('home.howItWorks.howItWorks')}
              </Heading>
              <Paragraph className="mt-2 sm:mt-3 text-white" variant="body3">
                {t('home.howItWorks.ifYouAreStillNotSure')}
              </Paragraph>
              {/* Desktop carousel arrows */}
              <HowItWorksCarouselArrows
                size="lg"
                className="hidden sm:flex"
                nextSlideIndex={nextSlideIndex}
                numberOfItems={items.length}
                carouselRef={carouselRef}
              />
            </div>
          </div>
          <div className="sm:flex-1 sm:max-w-[50%]">
            <HowItWorksCarousel onSlide={handleOnSlide} carouselRef={carouselRef}>
              {items.map((item, i) => (
                <HowItWorksCarouselItem {...item} key={`how-it-works-item-${i}`} />
              ))}
            </HowItWorksCarousel>
          </div>
        </div>
      </HeroImage>
    </div>
  );
};

export default HomeHowItWorks;
