import { ICashbackCampaign, ICashbackType } from '@poinz/api';

export const getCampaignForCashback = (id: number | null, campaigns: ICashbackCampaign[]) => {
  // if there is no affiliateComissionId (id) we presume it's wordline campaign
  // then search for campaign where comissionGroupIds is empty array as it means campaign is compatible with wordline
  // otherwice it's for affiliate but in order to apply campaign on affiliate id has to be included in comissionGroupIds array

  const campaign = !id
    ? campaigns.find(camp => !camp.commissionGroupIds.length)
    : campaigns.find(camp => camp.commissionGroupIds.includes(id));

  return !!campaign && !!campaign.cashbackValue
    ? {
        value: campaign.cashbackValue,
        type: campaign.cashbackType || ICashbackType.PERCENTAGE,
        endDate: campaign.endDate,
        eligibility: campaign.eligibility
      }
    : undefined;
};

// Helper function to format URL query parameters
export const formatQueryParams = (params: any) => {
  return Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');
};
