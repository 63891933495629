import { Language } from 'types/lang';

export const WITHDRAW_LIMIT = 100;

export const formatPercentage = ({ value = 0, language = Language.DE }) =>
  value.toLocaleString(language, {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });

export const formatNumber = ({ value = 0, showCurrency = false }) => {
  const decimalNumber = value.toFixed(2);
  return showCurrency ? `CHF ${decimalNumber}` : decimalNumber;
};

export const formatSavingPercentage = ({
  price,
  discountedPrice
}: {
  price: number;
  discountedPrice: number;
}) => {
  const value = ((price - discountedPrice) * 100) / price;
  return `${value.toFixed(0)}%`;
};

export const isNumberInRange = ({
  number,
  min,
  max
}: {
  number: number;
  min: number;
  max: number;
}) => {
  return number >= min && number <= max;
};

export const addTrailingZero = (num: number) => {
  return num < 10 ? `0${num}` : `${num}`;
};
