import * as React from 'react';
import clsx from 'clsx';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const SETTINGS = Object.freeze({
  className: 'item-slider',
  dots: false,
  speed: 400,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: false,
  centerPadding: '24px',
  focusOnSelect: true,
  infinite: false,
  variableWidth: true
});

const MobileItemCarousel: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={clsx('MobileItemCarousel', className)}>
      <Slider {...SETTINGS}>{children}</Slider>
    </div>
  );
};

export default MobileItemCarousel;
