import { ICashbackType, IProductTypes, ISearchParams } from '@poinz/api';
import { formatPercentage } from './number';

// TODO: align all formating money/numbers/cashback functions into one file (eg. utils/number)
/*
  - align output formats for money and cashbacks
  - as per Kim money should be 10.00 (always to decimals), cashback should be without decimals if possible with maximum 2
*/
export const formatPrice = (price: number, showChf = false, forceSign = false): string => {
  // remove "-"" sign if value is negative
  const formattedPrice = Math.abs(price).toFixed(2);

  // prefix should be before CHF or formattedPrice
  // prefix can be forced (+ pruce or - price)
  const signPrefix = price < 0 ? '- ' : forceSign ? '+ ' : '';

  return showChf ? `${signPrefix}CHF ${formattedPrice}` : `${signPrefix}${formattedPrice}`;
};

export const formatCashbackValue = (value: number, type: ICashbackType, numOfDecimalPlaces = 2) => {
  if (value && type === ICashbackType.PERCENTAGE) {
    if (numOfDecimalPlaces) {
      return `${(value * 100).toFixed(numOfDecimalPlaces).replace(',', '.')}%`;
    }
    return `${formatPercentage({ value: value * 100 }).replace(',', '.')}%`;
  }
  if (value && type === ICashbackType.AMOUNT) {
    if (numOfDecimalPlaces) {
      return `${value.toFixed(2)} CHF`;
    }
    return `${value} CHF`;
  }
  return value;
};

interface ISearchArguments {
  labels: string[];
  page: number;
  size?: number;
  query: string | null;
  searchableTypes?: IProductTypes[];
  lastChance?: boolean;
  cashbackFrom?: string[];
  cashbackTo?: string[];
  businessProfileIds?: string[];
  priceFrom?: string;
  priceTo?: string;
  sort?: string;
}

export const formatGlobalSearchParams = ({
  labels = [],
  page = 0,
  size = 20,
  query,
  searchableTypes,
  lastChance,
  cashbackFrom,
  cashbackTo,
  businessProfileIds,
  priceFrom,
  priceTo,
  sort
}: ISearchArguments): ISearchParams => {
  return {
    labels,
    page,
    size,
    query: query || null,
    searchableTypes,
    lastChance,
    cashbackFrom,
    cashbackTo,
    businessProfileIds,
    priceFrom,
    priceTo,
    sort
  } as ISearchParams;
};

export const formatSwissLoyaltyNumber = (slNumber: string) => {
  const splitted = slNumber.match(/.{1,4}/g);
  return Array.isArray(splitted) ? splitted.join(' ') : slNumber;
};

export const formatGiftCardCardNumber = (cardNumber: string | number) => {
  const stringNumber = cardNumber.toString();
  if (/^\d+$/.test(stringNumber)) {
    return formatSwissLoyaltyNumber(stringNumber);
  }
  return stringNumber;
};
