import * as React from 'react';
import clsx from 'clsx';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import Button from 'components/Button';

export interface HowItWorksCarouselItem {
  title: string;
  description: string;
  link: {
    pathname: string;
    label: string;
  };
}

const HowItWorksCarouselItem: React.FC<HowItWorksCarouselItem> = ({
  title,
  description,
  link: { pathname, label }
}) => {
  return (
    <div
      className={clsx(
        'HowItWorksCarouselItem w-[80vw] md:w-[360px] lg:w-[480px] max-w-[480px] px-6 py-[30px] md:px-16 md:py-20 rounded-3xl justify-between bg-white/20 backdrop-blur-[6px] flex flex-col mr-3 sm:mr-6 border-[1.5px] border-white min-h-full h-full items-stretch'
      )}
    >
      <div>
        <Heading className="text-white mb-4" variant="h3">
          {title}
        </Heading>
        <Paragraph className="text-white">{description}</Paragraph>
      </div>
      <Button
        variant="fifthly"
        className="sm:w-fit dark !bg-transparent hover:!bg-white/20 mt-6 text-center"
        href={pathname}
      >
        {label}
      </Button>
    </div>
  );
};

export default HowItWorksCarouselItem;
