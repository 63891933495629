import { DateTime } from 'luxon';
import { Language } from 'types/lang';

export const DATE_FORMAT = {
  FULL: DateTime.DATE_FULL,
  SHORT: DateTime.DATE_SHORT,
  DATE_WITH_SECONDS: DateTime.DATETIME_SHORT_WITH_SECONDS
};

export const LUXON_MIDNIGHT = { hour: 23, minute: 59, second: 59 };

export const formatDate = (
  date: string | Date | null,
  format?: keyof typeof DATE_FORMAT,
  locale: string = Language.DE
): string => {
  if (!date) {
    return '';
  }
  return DateTime.fromISO(date.toString())
    .setLocale(locale)
    .toLocaleString(format ? DATE_FORMAT[format] : DATE_FORMAT.FULL);
};

export const dateHasExpired = (date: string) => {
  const dateToCheck = DateTime.fromISO(date);
  const now = DateTime.now();

  return dateToCheck < now;
};
