import { useTranslation } from 'next-i18next';
import { routes } from 'utils/routes';
import { HowItWorksCarouselItem } from './HowItWorksCarousel/HowItWorksCarouselItem';
import { IProductTypes } from '@poinz/api';
import usePoinzCardsRouterLink from 'hooks/usePoinzCardsRouterLink';

export const useGetHowItWorksCarouselItems = (): Array<HowItWorksCarouselItem> => {
  const { t } = useTranslation();
  const poinzCardsLink = usePoinzCardsRouterLink();
  const shopLink = routes.shop.index();

  return [
    {
      title: t('home.howItWorks.onlineCashback'),
      description: t('home.howItWorks.findYourFavoriteBrands'),
      link: {
        pathname: `${shopLink}?searchableTypes=${IProductTypes.AFFILIATE_ONLINE}`,
        label: t('home.howItWorks.takeMeToPoinzShop')
      }
    },
    {
      title: t('home.howItWorks.inStoreCashback'),
      description: t('home.howItWorks.activateAnyPaymentMethod'),
      link: {
        pathname: `${shopLink}?searchableTypes=${IProductTypes.AFFILIATE_OFFLINE}`,
        label: t('home.howItWorks.takeMeToPoinzShop')
      }
    },
    {
      title: t('home.howItWorks.digitalVouchers'),
      description: t('home.howItWorks.buyDigitalVoucher'),
      link: {
        pathname: `${shopLink}?searchableTypes=${IProductTypes.GIFT_CARD}`,
        label: t('home.howItWorks.takeMeToPoinzShop')
      }
    },
    {
      title: t('home.howItWorks.deals'),
      description: t('home.howItWorks.discoverDeals'),
      link: {
        pathname: `${shopLink}?searchableTypes=${IProductTypes.DEAL}`,
        label: t('home.howItWorks.takeMeToPoinzShop')
      }
    },
    {
      title: t('home.howItWorks.poinzCards'),
      description: t('home.howItWorks.getCreditCards'),
      link: {
        pathname: poinzCardsLink,
        label: t('home.howItWorks.applyNow')
      }
    },
    {
      title: t('home.howItWorks.stampCards'),
      description: t('home.howItWorks.collectStampCards'),
      link: {
        pathname: routes.downloadApp.index(),
        label: t('home.howItWorks.downloadThePoinzApp')
      }
    }
  ];
};
