export const FETCH_KEYS = Object.freeze({
  DEALS: 'DEALS',
  WALLET_ASSETS: 'WALLET_ASSETS',
  WALLET_ASSET: 'WALLET_ASSET',
  RECEIPT: 'RECEIPT',
  RECEIPTS: 'RECEIPTS',
  DEAL_BY_ID: 'DEAL_BY_ID',
  DEAL_BY_SLUG_OR_PREVIEW_ID: 'DEAL_BY_SLUG_OR_PREVIEW_ID',
  SEARCH: 'SEARCH',
  FEATURED_LIST: 'FEATURED_LIST',
  AFFILIATE_BY_SLUG: 'AFFILIATE_BY_SLUG',
  DIGITAL_VOUCHER_BY_SLUG: 'DIGITAL_VOUCHER_BY_SLUG',
  TRANSACTIONS: 'TRANSACTIONS',
  BANK_ACCOUNT: 'BANK_ACCOUNT'
});

export { getSearchFetchKey } from './shopSearch';
