import { useAppContext } from 'contexts/app';
import { routes } from 'utils/routes';

const usePoinzCardsRouterLink = () => {
  const { promotedReferralCampaignCode } = useAppContext();

  return promotedReferralCampaignCode
    ? routes.referral.code(promotedReferralCampaignCode)
    : routes.poinz.poinzCards.index();
};

export default usePoinzCardsRouterLink;
