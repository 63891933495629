import * as React from 'react';
import TrustpilotWidget from 'components/Trustpilot/TrustpilotWidget';
import Heading from 'components/text/Heading';
import { useTranslation } from 'next-i18next';
import View from 'components/View';

const TrustpilotHome: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="TrustpilotHome px-6 py-24 sm:py-32 bg-neutral-900 text-center border-t-[2px] border-b-white">
      <View className="sm:!max-w-[640px]">
        <Heading variant="h2" className="text-white mb-4">
          {t('home.trustpilot.description')}
        </Heading>
        <TrustpilotWidget />
      </View>
    </div>
  );
};

export default TrustpilotHome;
