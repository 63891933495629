import * as React from 'react';
import { VariantProps, cva, cx } from 'class-variance-authority';

export const paragraphVariants = cva('', {
  variants: {
    variant: {
      subtitle: 'text-subtitle',
      body1: 'text-body1',
      body2: 'text-body2',
      body3: 'text-body3',
      caption: 'text-caption'
    },
    color: {
      default: 'text-neutral-900 dark:text-neutral-50',
      subtle: 'text-neutral-600 dark:text-neutral-400',
      main: 'text-primary'
    }
  },
  defaultVariants: {
    variant: null,
    color: 'default'
  }
});

interface DivProps extends React.HTMLAttributes<HTMLDivElement> {
  element: 'div';
}
interface PProps extends React.HTMLAttributes<HTMLParagraphElement> {
  element?: 'p';
}
type BaseProps = DivProps | PProps;

export type ParagraphProps = BaseProps & VariantProps<typeof paragraphVariants>;

const Paragraph = React.forwardRef<HTMLParagraphElement | HTMLParagraphElement, ParagraphProps>(
  ({ variant, className, color, element = 'p', ...props }, ref) => {
    if (element === 'div') {
      return (
        <div
          className={cx(paragraphVariants({ variant, color, className }))}
          ref={ref}
          {...props}
        />
      );
    }
    return (
      <p className={cx(paragraphVariants({ variant, color, className }))} ref={ref} {...props} />
    );
  }
);

Paragraph.displayName = 'Paragraph';

export default Paragraph;
