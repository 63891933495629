import * as React from 'react';
import { BlogItemBase } from 'utils/contentful/blog';
import Heading from 'components/text/Heading';
import { useTranslation } from 'next-i18next';
import Paragraph from 'components/text/Paragraph';
import View from 'components/View';
import BlogCard from 'containers/Blog/BlogCard';
import MobileItemCarousel from 'components/Carousel/MobileItemCarousel';

interface Props {
  items: Array<BlogItemBase> | null;
}

const BlogFeaturedSection: React.FC<Props> = ({ items }) => {
  const { t } = useTranslation();

  if (!items?.length) {
    return null;
  }

  return (
    <div className="BlogFeaturedSection py-24 sm:py-32 bg-neutral-900">
      <View className="mx-auto bg-neutral-900">
        <div className="text-center max-w-[640px] mx-auto">
          <Heading variant="h2" className="!leading-[1.2] md:!text-[48px] !text-2xl text-white">
            {t('home.blog.poinzBlog')}
          </Heading>
          <Paragraph variant="body2" className="text-white mt-2 md:mt-4">
            {t('home.blog.discoverTips')}
          </Paragraph>
        </div>

        <div className="hidden sm:grid grid-cols-3 gap-6 pb-3 mt-16 -mx-6 p-6 overflow-auto">
          {items.map((item, index) => {
            return <BlogCard className="text-white" key={`blog-featured-${index}`} blog={item} />;
          })}
        </div>

        {/* mobile */}
        <MobileItemCarousel className="block sm:hidden mt-12 -mx-6 p-0 overflow-hidden">
          {items.map((item, index) => (
            <BlogCard
              className="text-white w-[80vw] max-w-[480px] mr-6"
              key={`blog-featured-${index}`}
              blog={item}
            />
          ))}
        </MobileItemCarousel>
      </View>
    </div>
  );
};

export default BlogFeaturedSection;
