import * as React from 'react';
import clsx from 'clsx';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useIsScreenLarger } from 'utils/screen';

interface Props {
  children: React.ReactNode;
  className?: string;
  carouselRef: React.Ref<any>;
  onSlide: (nextSlide: number) => void;
}

const SETTINGS = Object.freeze({
  className: 'how-it-works-slider item-slider',
  speed: 0,
  centerMode: false,
  centerPadding: '24px',
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  arrows: false,
  draggable: false
});

const HowItWorksCarousel: React.FC<Props> = ({ children, className, carouselRef, onSlide }) => {
  const isLg = useIsScreenLarger();
  const handleBeforeChange = (currentIndex: number, nextIndex: number) => {
    onSlide(nextIndex);
  };

  return (
    <div className={clsx('HowItWorksDesktopCarousel', className)}>
      {/* Desktop */}
      <Slider
        beforeChange={handleBeforeChange}
        ref={isLg ? carouselRef : null}
        {...SETTINGS}
        className={clsx(SETTINGS.className, '!hidden sm:!block')}
      >
        {children}
      </Slider>
      {/* Mobile */}
      <Slider
        beforeChange={handleBeforeChange}
        ref={isLg ? null : carouselRef}
        {...SETTINGS}
        speed={400}
        className={clsx(SETTINGS.className, 'sm:!hidden')}
      >
        {children}
      </Slider>
    </div>
  );
};

export default HowItWorksCarousel;
