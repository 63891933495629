import * as React from 'react';
import { BlogItemBase } from 'utils/contentful/blog';
import Link from 'components/Link';
import { routes } from 'utils/routes';
import Image from 'next/image';
import Paragraph from 'components/text/Paragraph';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { formatDate } from 'utils/date';
import clsx from 'clsx';

interface Props {
  blog: BlogItemBase;
  rootClassName?: string;
  className?: string;
}

const BlogCard: React.FC<Props> = ({ blog, className, rootClassName }) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  return (
    <Link href={routes.blog.slug(blog.slug)} className={rootClassName}>
      <div className={clsx('BlogItem group', className)}>
        <div className="aspect-video relative rounded-2xl overflow-hidden">
          <Image
            src={blog.image.url}
            alt={blog.image.title}
            loading="lazy"
            fill
            className="object-cover brightness-[.8] group-hover:brightness-[.7]"
          />
        </div>
        <div className="flex gap-x-2 mt-3 sm:mt-4">
          <Paragraph variant="caption" className="!text-inherit">
            {formatDate(blog.sys.firstPublishedAt, 'FULL', locale)}
          </Paragraph>
          <Paragraph variant="caption" className="!text-inherit">
            |
          </Paragraph>
          <Paragraph variant="caption" className="!text-inherit">
            {t('blog.readMin', { minutes: blog.readingTime })}
          </Paragraph>
        </div>
        <Paragraph className="mt-2 font-semibold line-clamp-2 !text-inherit">
          {blog.title}
        </Paragraph>
        <Paragraph className="mt-1 line-clamp-2 !text-inherit">{blog.summary}</Paragraph>
      </div>
    </Link>
  );
};

export default BlogCard;
